.btn-leave-mobile {
    display: none!important;
}
.btn-leave
{
    display: none!important;
}
.iframe-container
{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height:100%;
}