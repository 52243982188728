.gradient-wrapper {
    background-color: #ff9ad5;
    filter: blur(80px);
    border-radius: 25%;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 31rem;
    display: flex;
    position: absolute;
    inset: 50% 0% auto;
}

@media (max-width: 640px) {
    .gradient-wrapper {
        inset: 40% 0% auto;
    }
}
.homepara
{
    max-width: 26rem;
    margin-left: auto;
    margin-right: auto;
    font-family: DM Mono, sans-serif;
    font-size: 1.25rem;
}