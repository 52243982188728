@tailwind base;
@tailwind components;
@tailwind utilities;

.aspect-square {
  aspect-ratio: 1 / 1;
}

.bgx {
  background: linear-gradient(#1d0725, #1d1945 46%, #0000 59%, #0000);
}
.bgx2{
  background: linear-gradient(#1d0725, #1d1945 46%, #0000 100%, #0000);
}
.gradient-wrapper2 {
  background-color: #ff9ad5;
  filter: blur(80px);
  border-radius: 25%;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 31rem;
  display: flex;
  position: absolute;
  inset: 25% 0% auto;
  z-index: 0;
  opacity: .8;
}
@keyframes progress {
  0% {
      width: 0%;
  }
  100% {
      width: 100%;
  }
}

.animate-progress {
  animation: progress 3s linear forwards;
}

.btnx {
  grid-column-gap: .5rem;
  grid-row-gap: .5rem;
  background-color: #f230aa;
  text-align: center;
  border-radius: 100vw;
  justify-content: center;
  align-items: center;
  padding: 1rem 1.5rem;
  font-size: 1rem;
  line-height: 1;
  transition: transform .2s, box-shadow .2s;
  display: flex;
  box-shadow: 0 .5rem 1.5rem #0000001a;
}
.btnxd{
  grid-column-gap: .5rem;
  grid-row-gap: .5rem;
  text-align: center;
  border-radius: 100vw;
  justify-content: center;
  align-items: center;
  padding: 1rem 1.5rem;
  font-size: 1rem;
  line-height: 1;
  transition: transform .2s, box-shadow .2s;
  display: flex;
  box-shadow: 0 .5rem 1.5rem #0000001a;
}
.hover-grow {
  transition: transform 0.3s ease-in-out;
}
@supports (-o-object-fit: fill) {
  #detach-button-host {
    display: none !important;
  }
}
@keyframes upDown {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}
.up-down-animation {
  animation: upDown 2s ease-in-out infinite;
}

.hover-grow:hover {
  transform: scale(1.02);
}
.uploading-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  z-index: 1000;
}

@keyframes loading {
  0%, 100% {
    margin-top: 25px;
    height: 10px;
  }

  50%{
    margin-top: 0px;
    height: 50px;
  }
}

ul.audioAnimation {
  display: inline-flex;
  margin: 0;
  padding: 0;
  list-style: none;
  gap: 5px;
}

li.audioAnimation {
  background-color: #f230aa;
  width: 10px;
  height: 10px;
  animation: loading 1s ease-in-out infinite;
  animation-fill-mode: forwards;
}

li.audioAnimation:nth-child(1) {
  animation-delay: 0s;animation-fill-mode: forwards;
}

li.audioAnimation:nth-child(2) {
  animation-delay: -0.2s;animation-fill-mode: forwards;
}

li.audioAnimation:nth-child(3) {
  animation-delay: -0.4s;animation-fill-mode: forwards;
}

li.audioAnimation:nth-child(4) {
  animation-delay: -0.6s;animation-fill-mode: forwards;
}

li.audioAnimation:nth-child(5) {
  animation-delay: -0.8s;animation-fill-mode: forwards;
}
.webcam-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.webcam, .webcam-disabled {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.webcam-disabled {
  background-color: #4a5568; /* bg-gray-600 */
}
