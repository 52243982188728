.App {
  text-align: center;
}
*{
  font-family: 'Reader', sans-serif;
}
@font-face {
  font-family: 'Reader';
  src: url('https://cdn.prod.website-files.com/63b2f566abde4cad39ba419f/66b9860ff53a504eee5d30bf_reader-regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.btnx
{
    grid-column-gap: .5rem;
    grid-row-gap: .5rem;
    background-color: #f230aa;
    text-align: center;
    border-radius: 100vw;
    justify-content: center;
    align-items: center;
    padding: 1rem 1.5rem;
    font-size: 1rem;
    line-height: 1;
    transition: transform .2s, box-shadow .2s;
    display: flex;
    box-shadow: 0 .5rem 1.5rem #0000001a;
}
.btnx.h:hover
{
    border-color: #f230aa;
    -webkit-backdrop-filter: blur(18px);
    backdrop-filter: blur(18px);
    background-color: #ffffff0d;
    border: 1px solid  #f230aa;
    transition: background-color .2s, transform .2s, box-shadow .2s;
}

.btnxcancel
{
    grid-column-gap: .5rem;
    grid-row-gap: .5rem;
    background-color: inherit;
    text-align: center;
    border-radius: 100vw;
    justify-content: center;
    align-items: center;
    padding: 1rem 1.5rem;
    font-size: 1rem;
    line-height: 1;
    transition: transform .2s, box-shadow .2s;
    display: flex;
    box-shadow: 0 .5rem 1.5rem #0000001a;
    border: 1px solid #f230aa;
    border-color: #f230aa;
}
.btnxcancel.h:hover
{
    border-color: #f230aa;
    -webkit-backdrop-filter: blur(18px);
    backdrop-filter: blur(18px);
    background-color: #f230aa;
    border: 1px solid  #f230aa;
    transition: background-color .2s, transform .2s, box-shadow .2s;
}

.btnxcancel:hover
{
    background-color: #f230aa;
    border: 1px solid  #f230aa;
    transition: background-color 0.5s, transform .2s, box-shadow .2s;
}

.btnx.second
{
    border-color: #f230aa;
    -webkit-backdrop-filter: blur(18px);
    backdrop-filter: blur(18px);
    background-color: #ffffff0d;
    border: 1px solid  #f230aa;
    transition: background-color .2s, transform .2s, box-shadow .2s;
}
@keyframes blink {
  0%, 100% {
    fill: #ff0000;
  }
  50% {
    fill: #ffffff;
  }
}

.animate-blink {
  animation: blink 1s infinite;
}
.bg-j-pink
{
  background-image: linear-gradient(36deg, #f57fc6, #a907a2 182.27%);
}
.bg-j-orange
{
  background-image: linear-gradient(237deg, #f73db2 -73.33%, #f6ca5e 113.89%);
}
.blur-noise {
  filter: blur(5px) grayscale(100%);
  background-size: cover;
}
.fixed-size-video {
  min-width: 100%;
  width: 440px; /* Ancho fijo */
  height: 384px; /* Alto fijo */
}
.bgx3
{
    background: linear-gradient(to right, #ff9ad5, #c076a1,  #ff9ad5);
    margin: 0;
    min-height: 100vh;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}


@keyframes pulse {
  0% {
      transform: scale(1);
  }
  50% {
      transform: scale(1.5);
  }
  100% {
      transform: scale(1);
  }
}


.base-timer__circle {
  fill: none;
  stroke: none;
}

.base-timer__path-elapsed {
  stroke-width: 7px;
  stroke: grey;
}

.base-timer__path-remaining {
  stroke-width: 7px;
  stroke-linecap: round;
  transform: rotate(90deg);
  transform-origin: center;
  transition: 1s linear all;
  fill-rule: nonzero;
  stroke: currentColor;
}

.base-timer__path-remaining.green {
  color: rgb(65, 184, 131);
}

.base-timer__path-remaining.orange {
  color: orange;
}

.base-timer__path-remaining.red {
  color: red;
}

.voice-visualizer__canvas-container {
  border-radius: 6px;
}

#webpack-dev-server-client-overlay {
  display: none !important;
}